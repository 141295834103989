<template>
  <p>Page Settings Manager ChannelSubscription</p>
</template>


<script>
export default {
  name: 'PageSettingsManagerChannelSubscription'
}
</script>
